import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"
import PageLead from "../../components/pageLead";

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Layout>
      <div className="privacy-policy-page">
        <PageLead sub="PRIVACY POLICY"
                  title="プライバシーポリシー"
        />
        <div className="contents">
          {ReactHtmlParser(
            data.strapiPrivacyPolicyPrivacyPolicyTextnode?.privacy_policy ?? ""
          )}
          <h2 className="section-title">個人情報の取扱いについて</h2>
          {ReactHtmlParser(
            data.strapiPersonalInformationPersonalInformationTextnode
              ?.personal_information ?? ""
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="プライバシーポリシー"
    description="OKULAB（オクラボ）のプライバシーポリシー、個人情報の取り扱いについて。"
  />
)
export default PrivacyPolicyPage

export const query = graphql`
  query {
    strapiPrivacyPolicyPrivacyPolicyTextnode {
      privacy_policy
    }
    strapiPersonalInformationPersonalInformationTextnode {
      personal_information
    }
  }
`
